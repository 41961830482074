

























































































































/* eslint-disable */
import { getApiUrl } from '@/inc/app.config'
import { useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import axios from 'axios'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import { getCallbackUrl } from '@/inc/utils'
import { Devis } from '@/inc/types'

export default defineComponent({
  name: 'step-three',
  components: {FeedbackMessage},
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { i18nSuivi } = useState('my', ['i18n', 'i18nSuivi'])
    const returnValidate = ref(false)
    const errorMsg = ref(false)
    const errorAucuneEtude = ref(false)
    const THREE = ref('3')
    const THIRTYONE = ref('31')
    const THIRTYTWO = ref('32')
    const THIRTYTHREE = ref('33')
    const hasError = ref(false)
    const feedbackMessage = ref('')

    var devisStatus = ref<any>()
    const montantRestantDu = ref<any | null>()
    const montantRestant = ref<any | null>()
    for (let i in _props.parentData?.liste) {
      if (_props.parentData?.liste[i].codeInfo === '32_2')
        montantRestantDu.value = _props.parentData?.liste[i]
      if (_props.parentData?.liste[i].codeInfo === '32_1')
        montantRestant.value = _props.parentData?.liste[i]
    }

    onBeforeMount(async () => {
      if (_props.parentData.codeStatut === '32') {
        getEtude(
          _props.parentData.numDossier[0],
          _props.parentData.idPartenaire
        )
      }
    })

    const getEtude = async (ordre, idPartenaire) => {
      await axios
        .get(`${getApiUrl()}/works/estimate`, {
          params: {
            Ordre: ordre,
            PartenaireId: idPartenaire,
            DevisType: 'ETUDE',
          },
        })
        .then(response => {
          if (response.status === 200) {
            devisStatus.value = response.data as Devis
          }
        })
        .catch(e => {
          if (
            e.response.data.message ===
            'Aucun devis/étude ne correspond à la sélection!'
          ) {
            errorAucuneEtude.value = true
          } else {
            errorMsg.value = true
          }
        })
    }
    const processHtml = (text: string, price: string) => {
      let html = text.replace('%montantFinal', price)
      let returnResult = html.replace('%devise', devisStatus.value.devise)
      return returnResult
    }
    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-three')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })
    // Methode permettant d aller sur la page de paiement mollie
    const submitPayment = async () => {
      await axios
        .post(`${getApiUrl()}/works/estimate-payment`, {
          Ordre: _props.parentData.numDossier[0],
          PartenaireId: _props.parentData.idPartenaire,
          DevisType: 'ETUDE',
          RedirectUrl: `${getCallbackUrl(
            `/suivi-dossier/${_props.parentData.numDossier[0]}`
          )}`,
          Total: '100',
        })
        .then(response => {
          hasError.value = false
          if (response.status === 200) {
            window.location.href = response.data.checkout
          }
        })
        .catch(e => {
          hasError.value = true
          feedbackMessage.value = e.response.data.message
        })
    }

    return {
      submitPayment,
      returnValidate,
      errorMsg,
      errorAucuneEtude,
      devisStatus,
      i18nSuivi,
      montantRestant,
      montantRestantDu,
      THREE,
      THIRTYONE,
      THIRTYTWO,
      THIRTYTHREE,
      processHtml,
      hasError,
      feedbackMessage,
    }
  },
})
